<template>
  <App
    title="合同签署"
    left-arrow
  >
    <DynamicForm
      ref="form"
      v-model="model"
      :rules="rules"
      :fieldData="fieldData"
    />

    <Footer
      slot="footer"
      @on-save="onSave"
      @on-cancel="$page.close()"
    />
  </App>
</template>
<script>
import { offlineRegistration } from '@/api/order'
import DynamicForm from '_c/dynamicForm'
import Footer from '_c/business/footer'
import { Dialog, Toast } from 'vant'

export default {
  name: 'contract',
  components: {
    DynamicForm,
    Footer
  },
  data () {
    return {
      model: {
        contractType: '1',
        contractCode: '',
        initiateName: '',
        contractName: '',
        contract: []
      },
      rules: {
        contractType: [
          { required: true, message: '请选择签署方式' }
        ],
        contractCode: [
          { required: true, message: '请输入合同编号' }
        ],
        initiateName: [
          { required: true, message: '请输入发起方名称' }
        ],
        contractName: [
          { required: true, message: '请输入合同名称' }
        ],
        contract: [
          { required: true, message: '请上传合同' }
        ]
      },
      contractTypeList: [
        { label: '纸质合同', value: '1' },
        { label: '电子合同', value: '0' }
      ],
      orderInfo: {}
    }
  },
  computed: {
    fieldData () {
      return [
        {
          data: [
            { label: '签署方式', key: 'contractType', type: 'select', options: this.contractTypeList },
            { label: '合同编号', key: 'contractCode', props: { maxLength: 50 }, visible: this.model.contractType === '1' },
            { label: '发起方名称', key: 'initiateName', props: { maxLength: 50 }, visible: this.model.contractType === '1' },
            { label: '合同名称', key: 'contractName', props: { maxLength: 50 }, visible: this.model.contractType === '1' },
            { label: '上传合同', key: 'contract', type: 'uploader', direction: 'column', props: { maxCount: 1, accept: ['.pdf'] }, visible: this.model.contractType === '1' }
          ]
        }
      ]
    }
  },
  methods: {
    onSave () {
      this.$refs.form.validate().then(async valid => {
        if (!valid) return

        const params = {
          channel: this.$storage.get('channel'),
          orderNum: this.orderInfo.orderNum,
          consultantId: this.orderInfo.consultantId,
          consultantName: this.orderInfo.consultantName,
          contractType: this.model.contractType
        }

        if (this.model.contractType === '1') {
          Object.assign(params, {
            contractCode: this.model.contractCode,
            initiateName: this.model.initiateName,
            contractName: this.model.contractName,
            annexList: this.model.contract.map(item => {
              return {
                annexPath: item.url,
                annexFilename: item.file.name,
                annexSuffix: 'pdf',
                annexSize: item.file.size,
                annexUploadFilename: item.file.name
              }
            })
          })

          return Dialog.confirm({
            title: '请仔细核对纸质合同信息！',
            message: `<p>合同编号：${this.model.contractCode}</p><p>发起方名称：${this.model.initiateName}</p><p>合同名称：${this.model.contractName}</p>`,
            cancelButtonText: '返回修改',
            confirmButtonText: '确认提交',
            beforeClose: (action, done) => {
              if (action === 'confirm') {
                this.offlineRegistration(params).then(done)
              } else {
                done()
              }
            }
          }).catch(err => {
            console.log(err)
          })
        }
        this.offlineRegistration(params)
      })
    },
    offlineRegistration (params) {
      return this.$requestHandler(offlineRegistration, params).then(res => {
        if (res && res.success) {
          if (params.contractType === '0') {
            Dialog.alert({
              title: '提示',
              message: '电子合同签署已提交，客户将收到签署链接'
            }).then(() => this.$router.go(-1))
          } else {
            Toast('提交成功!')
            this.$router.go(-1)
          }
        } else {
          Toast('提交失败!')
        }
      })
    }
  },
  mounted () {
    this.orderInfo = this.$route.query
    console.log(this.orderInfo)
  }
}
</script>